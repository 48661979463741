import { FetchOptions } from '@canalplus/mycanal-fetch';
import { HapiPlatform, Platform } from '@canalplus/sdk-core';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { apiCallWithTokenpassRenewal } from '../../../../../../api/PersoApi/PersoApi';
import { getPublicConfig } from '../../../../../../helpers/config/config-helper';
import { useAppDispatch } from '../../../../../../helpers/hooks/useAppDispatch';
import Logger from '../../../../../../helpers/logger/logger-helper';
import { getPullingHapiStatusUrl } from '../../../../../../helpers/url/url-helper';
import {
  offerLocationSelector,
  offerZoneSelector,
  platformSelector,
} from '../../../../../../selectors/application/application-selectors';
import { profileIdSelector } from '../../../../../../store/slices/user-selectors';
import { HapiStatus } from '../../../../../../templates/FunnelTvod/stores/constants';

export type HapiOptions = {
  distmodes: string;
  domain: string;
  offerLocation: string;
  offerZone: string;
  operator: string;
  profileId?: number;
  service: string;
  timeout: number;
  version: string;
};

export type HapiStatusResponse = {
  eck: string;
  id: string;
  creationDate: string;
  playset: {
    contentId: string;
    idKey: string;
    comMode: string;
    distMode: string;
    drmType: string;
    functionalType: string;
    distTechnology: string;
    quality: string;
    hash: string;
  };
  status: HapiStatus;
  restricted: boolean;
  purchaseType: string;
  beneficiaryId: string;
  prices: {
    initialPrice: number;
    promotionalPrice: number;
    currency: string;
  };
  promotion: {
    id: string;
    name: string;
    nbUseLeft: number;
  };
  payment: {
    type: string;
    price: number;
    currency: string;
    info: {
      contractId: number;
      time: string;
      ticketId: string;
    };
  };
  content: {
    id: string;
    idKey: string;
    idEdito: string;
    objectType: string;
    contentType: string;
    title: string;
    parentalRatings: [];
    maturityRating: {
      value: string;
      authority: string;
      morality: string;
      parentalCodeLock: {
        playContent: boolean;
        displayContent: boolean;
        displayExplicitPicture: boolean;
        userOverloadable: boolean;
      };
    };
    partnerContentId: string;
  };
  availability: {
    startDate: string;
    endDate: string;
  };
};

/**
 * return the status of purchaseId order
 *
 * @param options params for hapi payload
 * @param purchaseId
 */
const getSaleStatusOrder =
  (options: HapiOptions, purchaseId: string): Redux.ThunkAction<Promise<HapiStatusResponse | null>> =>
  async (dispatch) => {
    const { distmodes, version, offerZone, domain, offerLocation, operator, profileId, service, timeout } = options;

    try {
      const urlHapi = getPullingHapiStatusUrl(purchaseId);

      const apiCall = async (apiCallOptions: FetchOptions) => {
        const headers = apiCallOptions.headers as Record<string, string>;
        if (!headers) {
          throw new Error('[HAPI:Sell/Purchase] headers tokenPass undefined');
        }
        const newOptions: FetchOptions = {
          headers: {
            'Content-Type': 'application/json',
            authorization: `PASS Token="${(headers || {}).tokenPass}"`,
            'xx-distmodes': distmodes,
            'xx-domain': domain,
            'xx-ol': offerLocation,
            'xx-operator': operator,
            'xx-oz': offerZone,
            ...(profileId !== undefined ? { 'xx-profile-id': profileId.toString() } : {}),
            'xx-service': service,
            'xx-api-version': version,
          },
          method: 'GET',
          timeout,
        };

        return fetch(urlHapi, newOptions);
      };

      const response = await dispatch(apiCallWithTokenpassRenewal(apiCall));

      if (!response) {
        throw new Error('[HAPI:Sell/Purchase] Fetch purchaseId status error');
      }

      const jsonResponse = await response.json();

      return jsonResponse;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      Logger.error(`[HAPI:Sell/Purchase] Fetch purchaseId status error : ${errorMessage}`);

      return null;
    }
  };

const getHapiXxOperator = (platform: Platform): HapiPlatform => HapiPlatform[platform];

export const useHapiStatus = (purchaseId = 'NO_PURCHASEID'): (() => Promise<HapiStatusResponse | null>) => {
  const dispatch = useAppDispatch();
  const distmodes = 'tvod';
  const version = '3.0';
  const applicationContext = useSelector(platformSelector);
  const offerLocation = useSelector(offerLocationSelector);
  const offerZone = useSelector(offerZoneSelector);
  const profileId = useSelector(profileIdSelector);
  const {
    api: {
      hapi: { xx_service: xxService, timeout },
    },
  } = getPublicConfig();

  return useCallback(async () => {
    /**
     * return hapi options for saleStatusOrder call
     */
    const createSaleOrderOptions = {
      distmodes,
      domain: offerZone,
      offerZone,
      offerLocation,
      operator: getHapiXxOperator(applicationContext),
      profileId,
      service: xxService,
      timeout,
      version,
    };
    const result = dispatch(await getSaleStatusOrder(createSaleOrderOptions, purchaseId));
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, purchaseId]);
};
