import classNames from 'classnames';
import { useId } from 'react';
import { StatusCheckSvg } from '../../exports/icons';
import { CheckboxProps } from './Checkbox.types';

/**
 * A Checkbox allows users to select multiple options from a set
 *
 * @example
 * <Checkbox label="Option 1" name="option" value="1" isChecked onChange={handleChange} isDisabled={false} isFormValidated={false} isRequired={false} />
 */
export function Checkbox({
  id,
  label,
  subLabel,
  name,
  value,
  isFormValidated,
  isChecked,
  isDisabled,
  isRequired,
  isVerticalSubLabel,
  'data-testid': dataTestId,
  onChange,
}: CheckboxProps): JSX.Element {
  const checkboxUniqueId = useId();
  const inputId = id || checkboxUniqueId;
  const hasError = isFormValidated && isRequired && !isChecked;

  const labelStyle = classNames('text-dt-theme-text-checkbox-checkbox-label mr-dt-spacing-100');
  const subLabelStyle = classNames('text-dt-theme-text-checkbox-checkbox-sublabel');

  return (
    <div
      data-testid={dataTestId}
      className={classNames('flex items-center w-auto h-auto font-dt-font-family-system', {
        'opacity-dt-opacity-50': isDisabled,
      })}
    >
      <div className="relative self-start min-w-[22px] size-[22px] box-border mr-dt-spacing-200">
        <input
          type="checkbox"
          id={inputId}
          name={name}
          checked={isChecked}
          value={value}
          disabled={isDisabled}
          onChange={onChange}
          required={isRequired}
          aria-checked={isChecked}
          aria-invalid={hasError ? true : undefined}
          className={classNames(
            'flex appearance-none size-full m-dt-spacing-none',
            'border-dt-theme-border-checkbox-checkbox border-solid border-dt-border-regular rounded-dt-radius-small',
            {
              'bg-dt-theme-surface-checkbox-checkbox': isChecked,
              'border-dt-theme-border-checkbox-checkbox-error': hasError,
              'cursor-pointer': !isDisabled,
              'cursor-not-allowed': isDisabled,
            }
          )}
        />
        {isChecked && (
          <StatusCheckSvg
            className={classNames('pointer-events-none absolute top-0 left-0 fill-dt-theme-icon-checkbox-icon', {
              'cursor-pointer': !isDisabled,
              'cursor-not-allowed': isDisabled,
            })}
          />
        )}
      </div>
      <label
        htmlFor={inputId}
        className={classNames(
          [
            'flex select-none self-end',
            'text-dt-font-size-16 leading-dt-font-line-height-20 font-dt-font-weight-regular',
          ],
          {
            'flex-row': !isVerticalSubLabel,
            'flex-col': isVerticalSubLabel,
            'cursor-pointer': !isDisabled,
            'cursor-not-allowed': isDisabled,
          }
        )}
      >
        {/* Label */}
        {label && (
          <span
            className={classNames(labelStyle, {
              'mb-dt-spacing-50': isVerticalSubLabel,
            })}
          >
            {label}
          </span>
        )}

        {/* SubLabel */}
        {subLabel && <span className={subLabelStyle}>{subLabel}</span>}
      </label>
    </div>
  );
}
